import { useState, useEffect } from 'react'
import 'isomorphic-fetch'
import isInBrowser from '@helpers/is-in-browser'
import mockPricingData from '@variables/pricing-mock.json'

// prettier-ignore
const regionData = {
  at: 'de-AT', au: 'en-AU', be: 'nl-BE',  br: 'pt-BR', ca: 'en-CA', ch: 'de-CH', cz: 'cs-CZ', de: 'de-DE', dk: 'da-DK', es: 'es-ES', fi: 'fi-FI', fr: 'fr-FR', gb: 'en-GB', hu: 'hu-HU', 
  ie: 'ga-IE', in: 'en-IN', it: 'it-IT', jp: 'ja-JP', kr: 'ko-KR', mx: 'es-MX', my: 'en-MY', nl: 'en-NL', no: 'no-NO', nz: 'en-NZ', pl: 'pl-PL', pt: 'pt-PT', se: 'sv-SE', sg: 'en-SG',
  
  hk: 'en-AU',
  ae: 'en-AE',
  eu: 'en-NL',
  amer: 'en-US',
  apac: 'en-AU',
  emea: 'en-GB',
  latam: 'es-AR'
}

// ********************************
// Use these vars in testing to show preview data and control the locale if desired.
const previewEnv = false
const previewLocale = null
const mockData = false
// ********************************

const baseURL = `https://damassets.autodesk.net/ppg${
  previewEnv ? '/stg' : ''
}/prices`
const fetchPriceData = (plc, country) => {
  const requestUrl = `${baseURL}/${plc}/${country}/commercial_single_user.json`
  return fetch(requestUrl)
    .then((response) => response.json())
    .catch((error) => {
      console.error('Error fetching pricing data:', error)
      return null
    })
}
const usePricingService = (plc, locale = 'en-US', useMockData) => {
  const [priceData, setPriceData] = useState()
  const [isPriceDataLoading, setIsPriceDataLoading] = useState(true)
  let location = null

  if (isInBrowser) {
    location = window.location.href
  }
  // Check for 'default' locale and replace it with 'en-US'
  if (locale === 'default' || locale === 'us') {
    locale = 'amer'
  }
  const country =
    !((previewLocale === 'ae' || locale === 'ae') && plc === 'BM36DP') &&
    regionData[previewLocale || locale]
  useEffect(() => {
    if (country) {
      setIsPriceDataLoading(true) // Set loading state to true when fetching starts
      if (
        (mockData || useMockData) &&
        window?.location?.href.match(
          /deploy-preview|localhost|local\.autodesk\.com/
        )
      ) {
        setPriceData(mockPricingData)
        setIsPriceDataLoading(false) // Set loading state to false when fetching ends
      } else if (plc) {
        fetchPriceData(plc, country).then((data) => {
          if (data) {
            setPriceData(data)
          } else {
            fetchPriceData(plc, 'en-US').then((data) => setPriceData(data))
          }
          setIsPriceDataLoading(false) // Set loading state to false when fetching ends
        })
      }
    }
  }, [country, plc, useMockData])

  return { priceData, isPriceDataLoading } // Return loading state along with priceData
}

export default usePricingService
