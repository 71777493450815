import PageContext from '@context'
import { multilinkToUrl } from '@helpers'
import usePricingService from '@hooks/use-pricing-service'
import { Box, useMediaQuery } from '@material-ui/core'
import makeStyles from '@material-ui/styles/makeStyles'
import renderBloks from '@renderBloks'
import { Accordion, MarkdownHandler } from '@system'
import Icon from '@system/icon'
import Price from '@system/price'
import ACC_THEME from '@themes'
import get from 'lodash/get'
import React, { useContext, useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import { Link as GatsbyLink } from 'gatsby'
import useParams from '@hooks/use-params'

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    position: 'relative',
    width: '100%',
  },
  cardRootVariantCard: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  cardRootVariantContact: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    textDecoration: 'none',
  },
  cardRootVariantRow: {
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  },
  cardContainer: {
    border: '1px solid #c4c4c4',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: theme.typography.fontWeightBold,
    gap: '12px',
    height: '100%',
    padding: '18px 24px',
  },
  cardContainerVariantCard: ({ hasMobileAccordion }) => ({
    border: '1px solid #c4c4c4',
    [theme.breakpoints.down('sm')]: {
      border: hasMobileAccordion ? 'none' : '1px solid #c4c4c4',
      padding: hasMobileAccordion ? '0 16px 16px 16px' : '16px',
    },
  }),
  cardContainerVariantContact: ({ hasMobileAccordion }) => ({
    backgroundColor: '#fff',
    border: `2px solid ${theme.palette.background.iris}`,
    transition: '0.3s background-color',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      border: hasMobileAccordion
        ? 'none'
        : `2px solid ${theme.palette.background.iris}`,
      padding: hasMobileAccordion ? '0 16px 16px 16px' : '16px',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  }),
  cardContainerVariantImage: {
    '& img': {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      padding: '0 16px',
      '& img': {
        display: 'block',
        width: '100%',
      },
      '& > div': {
        padding: '24px 0',
      },
    },
  },
  cardContainerVariantRow: {
    alignItems: 'center',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    height: 'unset',
    justifyContent: 'space-between',
    padding: '18px 8px 18px 24px',
    transition: '0.3s background-color',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 16px',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      minHeight: '114px',
    },
  },
  cardContentContainer: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    gap: '16px',
  },
  headingContainer: ({ cardVariant }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    [theme.breakpoints.up('md')]: {
      flexDirection: cardVariant === 'row' ? 'row' : 'column',
      gap: '16px',
      justifyContent: cardVariant === 'row' ? 'space-between' : null,
    },
  }),
  titleContainer: ({ cardVariant }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: cardVariant === 'row' ? '2px' : '8px',
  }),
  title: ({ cardVariant, hasMobileAccordion, variant }) => ({
    alignSelf: cardVariant === 'card' ? 'center' : null,
    display: 'flex',
    fontSize: variant === 'price' ? '22px' : '26px',
    lineHeight: '32px',
    marginBottom: cardVariant === 'card' ? '8px' : null,
    textAlign: cardVariant === 'card' ? 'center' : null,
    [theme.breakpoints.down('sm')]: {
      display: hasMobileAccordion ? 'none' : 'flex',
    },
  }),
  tooltipStyles: {
    marginLeft: '0.5rem',
  },
  subtitle: ({ cardVariant, checklist }) => ({
    fontSize: cardVariant === 'row' && checklist.length < 1 ? '14px' : '20px',
    fontWeight:
      cardVariant === 'contact'
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightRegular,
    lineHeight: cardVariant === 'row' && checklist.length < 1 ? '18px' : '26px',
  }),
  checklistContainer: {
    margin: '0 -10px',
    '& div': {
      fontSize: '16px',
    },
  },
  buildMonthlyTitle: {
    paddingBottom: '2rem',
    paddingTop: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0',
      paddingTop: '1.78rem',
    },
  },
  markdown: {
    p: {
      fontSize: '20px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '28px',
    },
    '& ul': {
      listStyleType: 'none',
      margin: '0',
      padding: '0',
      '& li': {
        backgroundImage:
          'url(https://a.storyblok.com/f/64835/x/8b90e816bc/circle-check-purple.svg)',
        backgroundPosition: '0 4px',
        backgroundSize: '18px 18px',
        backgroundRepeat: 'no-repeat',
        marginBottom: '10px',
        paddingLeft: '30px',
      },
    },
  },
  markdownBuild550: {
    '& p': {
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '18px',
      margin: '0',
    },
  },
  discountFlag: {
    color: '#fff',
    backgroundColor: theme.palette.text.iris,
    fontWeight: theme.typography.fontWeightBold,
    padding: '4px',
    textAlign: 'center',
    width: '100%',
  },
  discountFlagVariantRow: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
      left: '-20px',
      padding: '0',
      position: 'absolute',
      textOrientation: 'sideways',
      transform: 'rotate(180deg)',
      width: '20px',
      writingMode: 'vertical-rl',
    },
  },
  featuredText: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    padding: '10px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  ctaContainer: {
    '& a': {
      display: 'block',
    },
    '& button': {
      borderRadius: '0',
      justifyContent: 'space-between',
      padding: '14px 32px',
      width: '100%',
      '& span': {
        fontSize: '18px',
        '& svg': {
          height: '32px',
          width: '32px',
        },
      },
    },
  },
  chevron: {
    alignSelf: 'center',
    height: '32px',
    width: '32px',
  },
}))

const PricingCard = ({
  annualBilling,
  blok,
  hasLargePrice,
  hasTaxText,
  hideAnnualizedPricing,
  image,
  locale,
  pricingCardsModuleVariant,
  pricingCardsModuleHeading,
}) => {
  const {
    checklist,
    contactMarkdown,
    cta,
    plc,
    subtitle,
    title,
    variant,
    featuredText,
    mobilePreviewText,
    highlightColor,
    tooltip,
    hasMobileAccordion,
    useMockData,
  } = blok
  const { isInEditor, defaultPriceData } = useContext(PageContext)
  const [formattedPrices, setFormattedPrices] = useState({})
  const [cartUrls, setCartUrls] = useState({
    monthlyCartUrl: defaultPriceData ? defaultPriceData[plc]?.monthly : '',
    annualCartUrl: defaultPriceData ? defaultPriceData[plc]?.yearly : '',
  })
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const defaultVariant = variant && variant !== 'price' ? variant : 'card'
  const cardVariant = pricingCardsModuleVariant
    ? pricingCardsModuleVariant
    : defaultVariant

  const classes = useStyles({
    cardVariant,
    checklist,
    isDiscounted: formattedPrices.discountPercent,
    hasMobileAccordion,
    variant,
  })
  const isContactCard = cardVariant === 'contact'
  const isBuild550Monthly = plc === 'BLD550' && !annualBilling

  const { priceData, isPriceDataLoading } = usePricingService(
    plc,
    locale,
    useMockData
  )

  const billingPlans = priceData?.prices
  useEffect(() => {
    if (!!billingPlans && !isContactCard) {
      const getFormattedPrice = (priceNum, dispPriceCoeff = 1) => {
        const lang = priceData?.languageCode
        const country = priceData?.countryCode
        const currency =
          '\u00A0' + get(priceData?.currencyFormats, '[0]')?.symbol

        const formatted = new Intl.NumberFormat(
          `${lang || 'en'}-${country || 'US'}`,
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        ).format(parseInt(priceNum) * dispPriceCoeff)

        if (priceNum) {
          return country === 'US'
            ? `${currency || 'USD'}${formatted}` // Removed space after 'USD'
            : `${formatted}${currency || 'USD'}` // Removed space before 'USD'
        } else {
          return '$--'
        }
      }

      const calculateDiscount = (priceObj) => {
        const n = parseInt(priceObj.discount)
        const d = parseInt(
          priceObj.amountBeforeDiscountWithVat || priceObj.amountBeforeDiscount
        )
        const percentage = (n / d) * 100
        return percentage % 1 > 0.95
          ? Math.ceil(percentage)
          : Math.floor(percentage)
      }

      billingPlans.forEach((plan, index) => {
        const { currency, billingPeriodCount, billingPeriod } = plan
        let params = `&term=${billingPeriodCount}-${billingPeriod.toUpperCase()}&currency=${currency}`
        const customCartParam = `customizedCart=autodesk-construction-cloud-cart&from=${location}`
        const baseUrl = `https://web-engineering-svc.autodesk.com/redirectToCart/${plc}?${customCartParam}`
        plan.directToCartUrl = encodeURI(`${baseUrl}${params}`)
      })

      const monthlyPriceData = billingPlans?.find((el) => {
        return el.billingPeriod === 'MONTH' && el.billingPeriodCount === '1'
      })
      const annualPriceData = billingPlans?.find((el) => {
        return el.billingPeriod === 'YEAR' && el.billingPeriodCount === '1'
      })

      const monthlyPriceVal =
        monthlyPriceData?.amountAfterDiscountWithVat || monthlyPriceData?.amount

      const annualPriceVal =
        annualPriceData?.amountAfterDiscountWithVat || annualPriceData?.amount

      setFormattedPrices({
        monthlyPricePerMonth: getFormattedPrice(monthlyPriceVal),
        annualPricePerMonth: getFormattedPrice(annualPriceVal, 1 / 12),
        monthlyPricePerYear: getFormattedPrice(monthlyPriceVal, 12),
        annualPricePerYear: getFormattedPrice(annualPriceVal),
        monthlyPriceBeforeDiscount: getFormattedPrice(
          monthlyPriceData?.amountBeforeDiscountWithVat ||
            monthlyPriceData?.amountBeforeDiscount,
          12
        ),
        annualPriceBeforeDiscount: getFormattedPrice(
          annualPriceData?.amountBeforeDiscountWithVat ||
            annualPriceData?.amountBeforeDiscount
        ),
        discountPercent: calculateDiscount(
          annualBilling ? annualPriceData : monthlyPriceData
        ),
      })
      setCartUrls({
        monthlyCartUrl: monthlyPriceData?.directToCartUrl,
        annualCartUrl: annualPriceData?.directToCartUrl,
      })
    }
  }, [billingPlans, isContactCard, priceData, annualBilling])

  // Handle variant class names
  let classesCardRoot = [classes.cardRoot]
  let classesCardContainer = [classes.cardContainer]
  let classesDiscountFlag = [classes.discountFlag]

  if (cardVariant === 'card') {
    classesCardRoot.push(classes.cardRootVariantCard)
    classesCardContainer.push(classes.cardContainerVariantCard)
  }

  if (cardVariant === 'contact') {
    classesCardRoot.push(classes.cardRootVariantContact)
    classesCardContainer.push(classes.cardContainerVariantContact)
  }

  if (cardVariant === 'row') {
    classesCardRoot.push(classes.cardRootVariantRow)
    classesCardContainer.push(classes.cardContainerVariantRow)
    classesDiscountFlag.push(classes.discountFlagVariantRow)
  } else if (image?.[0]?.image) {
    classesCardContainer.push(classes.cardContainerVariantImage)
  }

  // Handle variant root element
  const ctaLink = get(cta, '[0]')
  const { params, forwardUTMParams } = ctaLink
  const baseHref =
    multilinkToUrl(ctaLink?.linksTo, isInEditor) ||
    cartUrls[annualBilling ? 'annualCartUrl' : 'monthlyCartUrl']
  // TODO: refactor useParams so that more of the string manipulation and concatenation happens in the hook itself - see hook use in button and link components for added context
  const query = useParams(params, baseHref, forwardUTMParams)
  const rootElHref = baseHref + query

  const RootEl = ({ children }) => {
    if (cardVariant === 'contact' || cardVariant === 'row') {
      return ctaLink?.linksTo?.linktype === 'story' ? (
        <GatsbyLink to={rootElHref} className={classesCardRoot.join(' ')}>
          {children}
        </GatsbyLink>
      ) : (
        <a className={classesCardRoot.join(' ')} href={rootElHref}>
          {children}
        </a>
      )
    }

    return <div className={classesCardRoot.join(' ')}>{children}</div>
  }

  return (
    <SbEditable content={blok}>
      <Accordion
        highlightColor={highlightColor}
        previewText={mobilePreviewText}
        title={title}
        pricingCardsModuleHeading={pricingCardsModuleHeading}
      >
        <RootEl>
          {featuredText ? (
            <Box className={classes.featuredText}>{featuredText}</Box>
          ) : null}
          {formattedPrices.discountPercent ? (
            <div className={classesDiscountFlag.join(' ')}>
              {`${formattedPrices.discountPercent}% OFF`}
            </div>
          ) : null}
          <div className={classesCardContainer.join(' ')}>
            <div className={classes.cardContentContainer}>
              <div className={classes.headingContainer}>
                <div className={classes.titleContainer}>
                  {title ? (
                    <div className={classes.title}>
                      {title}
                      {!!tooltip?.length &&
                        !handHeldDevice &&
                        renderBloks(tooltip, { styles: classes.tooltipStyles })}
                    </div>
                  ) : null}
                  {subtitle ? (
                    <div className={classes.subtitle}>{subtitle}</div>
                  ) : null}
                  {isBuild550Monthly ? (
                    <div className={classes.markdownBuild550}>
                      <MarkdownHandler>{contactMarkdown}</MarkdownHandler>
                    </div>
                  ) : null}
                </div>
                {!isContactCard && !isBuild550Monthly && plc ? (
                  <Price
                    isLoading={isPriceDataLoading}
                    alignRight={cardVariant === 'row'}
                    annualBilling={annualBilling}
                    formattedPrices={formattedPrices}
                    hasTaxText={hasTaxText}
                    hideAnnualizedPricing={hideAnnualizedPricing}
                    sizeLarge={hasLargePrice}
                  />
                ) : null}
              </div>
              {isContactCard ? (
                <div className={classes.markdown}>
                  <MarkdownHandler>{contactMarkdown}</MarkdownHandler>
                </div>
              ) : (
                <>
                  {checklist && checklist.length > 0 ? (
                    <div className={classes.checklistContainer}>
                      {renderBloks(checklist)}
                    </div>
                  ) : null}
                </>
              )}
            </div>
            {cardVariant !== 'row' && image?.[0]?.image ? (
              <img src={image?.[0]?.image} />
            ) : null}
            {cardVariant === 'row' ? (
              <Icon styles={classes.chevron}>ChevronRight</Icon>
            ) : null}
          </div>
          {cardVariant !== 'row' && cta && cta.length > 0 ? (
            <div className={classes.ctaContainer}>
              {renderBloks(cta, {
                isPricingCTA: true,
                parentUrl:
                  cartUrls[annualBilling ? 'annualCartUrl' : 'monthlyCartUrl'],
              })}
            </div>
          ) : null}
        </RootEl>
      </Accordion>
    </SbEditable>
  )
}

export default PricingCard
